import React, { useEffect, useState } from 'react'
import { Select } from '../../../../common/customComponents'
import { t } from '../../../../../infrastructure/i18nextHelper'
import { StockMovementContainer, StockMovementStore } from '../../stockMovementStore'
import { MovementForm, MovementType, PlantForm, StorageLocationForm } from '../../../stockModels'
import { api } from '../../../../../infrastructure/api'
import ReactDOM from 'react-dom'
import { hasFeature } from '../../../../../infrastructure/feature'

type PlantStorageLocationFormProps = {
    target: 'origin' | 'destination'
    isMainSapFlow: boolean
}

export let PlantStorageLocationForm = ({ target, isMainSapFlow }: PlantStorageLocationFormProps) => {
    let store = StockMovementContainer.useContainer()

    let [plants, setPlants] = useState<PlantForm[]>([])
    let [plantCodeChoices, setPlantCodeChoices] = useState<ListItem[]>([])
    let [storageLocationChoices, setStorageLocationChoices] = useState<ListItem[]>([])

    let dependencies = getPlantListDependencies(store.stockMovement, isMainSapFlow, target)

    useEffect(() => { updatePlantList() }, Object.values(dependencies))

    let sapFlow = store.getSapFlow(isMainSapFlow)

    let plantForm = {
        plant: target === 'origin' ? sapFlow.originPlant : sapFlow.destinationPlant,
        storageLocation: target === 'origin' ? sapFlow.originStorageLocation : sapFlow.destinationStorageLocation
    }

    let disabled = sapFlow.isReferenceOrderFromSap
        && sapFlow.referenceOrder != null
        && hasFeature('FreezePlantWhenSapEntered')

    useEffect(() => {
        setPlantCode(plantForm.plant, plants.find(x => x.code == plantForm.plant)?.storageLocations ?? null)
    }, [plantForm.plant])

    function setPlantCode(val: string | null, storageLocations: StorageLocationForm[] | null) {
        setStorageLocationChoices(storageLocations?.map(x => ({ value: x.code, text: x.code + ' - ' + x.name })) ?? [])
        let firstStorageLocationCode = storageLocations?.[0]?.code ?? null

        if (target === 'origin' && store.getSapFlow(isMainSapFlow).originPlant != val) {
            let newSapFlow = {
                ...store.getSapFlow(isMainSapFlow),
                originPlant: val,
                originStorageLocation: firstStorageLocationCode
            }

            if (isMainSapFlow
                && store.stockMovement.secondSapFlow
                && store.stockMovement.movementType == MovementType.Sale) {
                let newSecondSapFlow = {
                    ...store.getSapFlow(!isMainSapFlow),
                    originPlant: val,
                    originStorageLocation: firstStorageLocationCode
                }

                store.setStockMovement({ ...store.stockMovement, mainSapFlow: newSapFlow, secondSapFlow: newSecondSapFlow })
            } else {
                store.setSapFlow(isMainSapFlow, newSapFlow)
            }
        }

        if (target === 'destination' && store.getSapFlow(isMainSapFlow).destinationPlant != val) {

            let newSapFlow = {
                ...store.getSapFlow(isMainSapFlow),
                destinationPlant: val,
                destinationStorageLocation: firstStorageLocationCode
            }

            if (isMainSapFlow
                && store.stockMovement.secondSapFlow
                && store.stockMovement.movementType == MovementType.Purchase) {
                let newSecondSapFlow = {
                    ...store.getSapFlow(!isMainSapFlow),
                    originPlant: val,
                    originStorageLocation: firstStorageLocationCode
                }

                store.setStockMovement({ ...store.stockMovement, mainSapFlow: newSapFlow, secondSapFlow: newSecondSapFlow })
            } else {
                store.setSapFlow(isMainSapFlow, newSapFlow)
            }
        }
    }

    function setStorageLocation(val: string | null) {
        if (val === plantForm.storageLocation) return

        if (target === 'origin') {
            if (isMainSapFlow
                && store.stockMovement.secondSapFlow
                && store.stockMovement.movementType == MovementType.Sale) {
                let movement = {
                    ...store.stockMovement,
                    mainSapFlow: { ...store.stockMovement.mainSapFlow, originStorageLocation: val },
                    secondSapFlow: { ...store.stockMovement.secondSapFlow, originStorageLocation: val }
                }

                store.setStockMovement(movement)
            }
            else
                store.setSapFlow(isMainSapFlow, { ...store.getSapFlow(isMainSapFlow), originStorageLocation: val })
        }
        else if (target === 'destination') {
            if (isMainSapFlow
                && store.stockMovement.secondSapFlow
                && store.stockMovement.movementType == MovementType.Purchase) {
                let movement = {
                    ...store.stockMovement,
                    mainSapFlow: { ...store.stockMovement.mainSapFlow, destinationStorageLocation: val },
                    secondSapFlow: { ...store.stockMovement.secondSapFlow, originStorageLocation: val }
                }

                store.setStockMovement(movement)
            } else
                store.setSapFlow(isMainSapFlow, { ...store.getSapFlow(isMainSapFlow), destinationStorageLocation: val })
        }
    }

    async function updatePlantList() {
        if (!dependencies.companyCode
            || !dependencies.dutyStatus
            || !dependencies.productId
            || !dependencies.site) return

        let movementPlant = target == "origin"
            ? store.getSapFlow(isMainSapFlow).originPlant
            : store.getSapFlow(isMainSapFlow).destinationPlant

        let newState = {
            plants: [] as PlantForm[],
            plantCodeChoices: [] as ListItem[],
            storageLocationChoices: [] as ListItem[],
            selectedPlant: movementPlant
        }

        newState.plants = await api.get<PlantForm[]>('stock/movement/plant', dependencies)
        if (isSamePlantsThanBefore()) return

        buildNewState()
        updateState()

        function isSamePlantsThanBefore() {
            return plantForm.plant != null
                && newState.plants.length === plants.length
                && newState.plants.every(x => plants.find(y => y.code === x.code))
                && newState.plants[0].storageLocations[0] === plants[0].storageLocations[0]
        }

        function buildNewState() {
            if (newState.plants.length === 0) {
                newState.plantCodeChoices = []
                newState.storageLocationChoices = []
                newState.selectedPlant = null
            }
            else {
                if (newState.selectedPlant == null || !newState.plants.map(x => x.code).includes(newState.selectedPlant))
                    newState.selectedPlant = newState.plants[0].code
                newState.plantCodeChoices = newState.plants.map(x => ({ value: x.code, text: x.code + ' - ' + x.name }))
                let storageLocationSource = newState.plants.find(x => x.code == newState.selectedPlant)?.storageLocations
                newState.storageLocationChoices = storageLocationSource?.map(x => ({ value: x.code, text: x.code + ' - ' + x.name })) ?? []
            }
        }

        function updateState() {
            plants = newState.plants

            ReactDOM.unstable_batchedUpdates(() => {
                setPlants(newState.plants)
                setPlantCodeChoices(newState.plantCodeChoices)
                setStorageLocationChoices(newState.storageLocationChoices)
                setPlantCode(newState.selectedPlant, newState.plants.find(x => x.code == newState.selectedPlant)?.storageLocations ?? null)
            })
        }
    }

    return (
        <>
            <Select
                label={t(`stock.label.movement.${target}Plant`)}
                disabled={plantCodeChoices.length <= 1 || disabled}
                value={plantForm.plant}
                choices={plantCodeChoices}
                status={getPlantStatus(store, target, isMainSapFlow)}
                onChange={val => setPlantCode(val, plants.find(x => x.code == val)?.storageLocations ?? null)} />
            <Select
                label={t('stock.label.movement.storageLocation')}
                disabled={storageLocationChoices.length <= 1 || disabled}
                value={plantForm.storageLocation}
                choices={storageLocationChoices}
                status={getStorageLocationStatus(store, target, isMainSapFlow)}
                onChange={val => setStorageLocation(val)} />
        </>
    )
}

function getPlantStatus(store: StockMovementStore, target: 'origin' | 'destination', isMainSapFlow: boolean) {
    return store.getSapFlowFieldStatus(isMainSapFlow).getStatus(target === 'origin' ? 'originPlant' : 'destinationPlant')
}

function getStorageLocationStatus(store: StockMovementStore, target: 'origin' | 'destination', isMainSapFlow: boolean) {
    return store.getSapFlowFieldStatus(isMainSapFlow).getStatus(target === 'origin' ? 'originStorageLocation' : 'destinationStorageLocation')
}

function getPlantListDependencies(mvt: MovementForm, isMainSapForm: boolean, target: 'origin' | 'destination') {
    if (isMainSapForm)
        return {
            id: mvt.id,
            companyCode: mvt.company,
            dutyStatus: target === 'origin' ? mvt.originDutyStatus : mvt.destinationDutyStatus,
            productId: target === 'origin' ? mvt.originProductId : mvt.destinationProductId,
            site: target === 'origin' ? mvt.originSite : mvt.destinationSite,
        }

    if (mvt.movementType == MovementType.Sale)
        return {
            id: mvt.id,
            companyCode: mvt.company,
            dutyStatus: mvt.originDutyStatus,
            productId: mvt.originProductId,
            site: mvt.originSite,
        }

    return {
        id: mvt.id,
        companyCode: mvt.company,
        dutyStatus: mvt.destinationDutyStatus,
        productId: mvt.destinationProductId,
        site: mvt.destinationSite,
    }
}

type ListItem = { value: string, text: string }