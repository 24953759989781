import React, { useEffect, useState } from 'react'
import { IconButton, Tooltip, Typography, Checkbox, createStyles, withStyles } from '@material-ui/core'
import { DoubleArrow, FlipOutlined } from '@material-ui/icons'
import { popupNavigator } from '../../../../infrastructure/popupNavigator'
import { t } from '../../../../infrastructure/i18nextHelper'
import { defaultColors, defaultStyles, muiOptions } from '../../../../infrastructure/materialUiThemeProvider'
import { hasFeature } from '../../../../infrastructure/feature'
import { DatePicker, Select, TextField, NumberField, Button, TimePicker } from '../../../common/customComponents'
import { useInputDebounce } from '../../../common/debounce'
import { StockMovementContainer } from '../stockMovementStore'
import { LinkVesselMovementContainer } from './linkVesselContainer'
import { MovementStatus, MovementType, MovementForm } from '../../stockModels'
import moment from 'moment'
import { CommentContent } from '../../../common/comment/commentContent'
import { CommentButton } from '../../../common/comment/commentButton'
import { updateDateTime } from '../../../common/dateTimeHelper'

let tBase = 'stock.label.movement.'

type MainSapFlowProps = {
    isMainSapFlow: boolean
}

export let Volume = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let allowNegative =
        hasFeature('AllowPipeMovementNegativeQuantity')
        && store.stockMovement.meanOfTransportation === 'Pipe'
        && !!store.stockMovement.movementType
        && [MovementType.Purchase, MovementType.Sale, MovementType.Transfer].includes(store.stockMovement.movementType)
    return (
        <NumberField
            label={t(tBase + 'volume', { unit: store.unit })}
            disabled={!store.canEditMovementMetadata() || store.stockMovement.nomination}
            text={store.stockMovement.volume}
            allowNegative={allowNegative}
            onChange={x => store.setStockMovement({ ...store.stockMovement, volume: x })}
            status={store.formErrors.movementFieldStatus.getStatus('volume')} />
    )
}

export let ObservedVolume = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    if (store.stockMovement.nomination) return <></>

    return (
        <NumberField
            label={t(tBase + 'observedQuantity')}
            disabled={!!store.associatedTruckId || store.stockMovement.readOnly}
            text={store.stockMovement.observedQuantity}
            onChange={x => store.setStockMovement({ ...store.stockMovement, observedQuantity: x })}
            status={store.formErrors.movementFieldStatus.getStatus('observedQuantity')} />
    )
}

export let ObservedTemperature = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    if (store.stockMovement.nomination) return <></>
    return (
        <NumberField
            label={t(tBase + 'observedTemperature')}
            disabled={!!store.associatedTruckId || store.stockMovement.readOnly}
            text={store.stockMovement.observedTemperature}
            onChange={x => store.setStockMovement({ ...store.stockMovement, observedTemperature: x })}
            status={store.formErrors.movementFieldStatus.getStatus('observedTemperature')} />
    )
}

export let Nomination = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()

    return (
        <div>
            <Typography variant='subtitle2' color='textSecondary'>{t(tBase + 'nomination')}</Typography>
            <Checkbox checked={store.stockMovement.nomination}
                onChange={x => store.setStockMovement({ ...store.stockMovement, nomination: x.target.checked, volume: store.stockMovement.nominatedVolume })} />
        </div>
    )
}

export let NominatedVolume = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()

    return (
        <NumberField
            label={t(tBase + 'nominatedVolume', { unit: store.unit })}
            disabled={!store.canEditMovementMetadata()}
            text={store.stockMovement.nominatedVolume}
            onChange={x => store.setStockMovement(store.stockMovement.nomination ? { ...store.stockMovement, nominatedVolume: x, volume: x } : { ...store.stockMovement, nominatedVolume: x })} />
    )
}

export let Date = ({ isIn }: { isIn: boolean }) => {
    let store = StockMovementContainer.useContainer()
    let value = isIn ? store.stockMovement.stockInputDate : store.stockMovement.stockOutputDate
    let isBatch = store.batchMovement != null
    let getStatus = store.formErrors.movementFieldStatus.getStatus

    let setDate = (newDate) => {
        let type = store.stockMovement.movementType
        let onlyIn = type == MovementType.Borrow || type == MovementType.Purchase || type == MovementType.Gains
        let onlyOut = type == MovementType.Loan || type == MovementType.Sale || type == MovementType.Losses
        let change =
            onlyIn ? { ...store.stockMovement, stockInputDate: newDate }
                : onlyOut ? { ...store.stockMovement, stockOutputDate: newDate }
                    : { ...store.stockMovement, stockInputDate: newDate, stockOutputDate: newDate }
        store.setStockMovement(change)
    }

    return (
        isBatch ? null :
            <DatePicker label={t(tBase + 'date')} date={value}
                disabled={!store.canEditMovementMetadata()}
                setDate={setDate}
                status={isIn ? getStatus('stockInputDate') : getStatus('stockOutputDate')} />
    )
}

type DateProps = {
    onChangeOverride?: (mvt: MovementForm) => void
    disabled?: boolean
    label?: string
}
export let InputDate = ({ disabled, label, onChangeOverride }: DateProps) => {
    let store = StockMovementContainer.useContainer()
    let value = store.stockMovement.stockInputDate
    let isBatch = store.batchMovement != null
    let getStatus = store.formErrors.movementFieldStatus.getStatus
    let onChange = onChangeOverride ?? store.setStockMovement

    return (
        isBatch ? null :
            <DatePicker label={label ?? t(tBase + 'inputDate')} date={value}
                disabled={!store.canEditMovementMetadata() || disabled}
                setDate={newDate => onChange({ ...store.stockMovement, stockInputDate: newDate })}
                status={getStatus('stockInputDate')} />
    )
}

export let OutputDate = ({ disabled, label, onChangeOverride }: DateProps) => {
    let store = StockMovementContainer.useContainer()
    let value = store.stockMovement.stockOutputDate
    let isBatch = store.batchMovement != null
    let getStatus = store.formErrors.movementFieldStatus.getStatus
    let onChange = onChangeOverride ?? store.setStockMovement

    return (
        isBatch ? null :
            <DatePicker label={label ?? t(tBase + 'outputDate')} date={value}
                disabled={!store.canEditMovementMetadata() || disabled}
                setDate={newDate => onChange({ ...store.stockMovement, stockOutputDate: newDate })}
                status={getStatus('stockOutputDate')} />
    )
}

export let RequestedDate = () => {
    let store = StockMovementContainer.useContainer()
    let isBatch = store.batchMovement != null

    return (
        isBatch ? null :
            <DatePicker label={t(tBase + 'requestedDate')} date={store.stockMovement.requestedDate}
                disabled={store.stockMovement.readOnly}
                setDate={newDate => store.setStockMovement({ ...store.stockMovement, requestedDate: newDate })}
                status={store.formErrors.movementFieldStatus.getStatus('requestedDate')} />
    )
}

export let OperationStartAt = () => {
    let store = StockMovementContainer.useContainer()
    let isBatch = store.batchMovement != null

    let [date, setDate] = useState(store.stockMovement.operationStartAt);
    let [time, setTime] = useState(store.stockMovement.operationStartAt);

    let handleDateChange = (newDate) => {
        setDate(newDate)
        let operationStartAt = updateDateTime(newDate, time)
        store.setStockMovement({ ...store.stockMovement, operationStartAt: operationStartAt })
    }

    let handleTimeChange = (newTime) => {
        setTime(newTime)
        let operationStartAt = updateDateTime(date, newTime)
        store.setStockMovement({ ...store.stockMovement, operationStartAt: operationStartAt })
    }

    return (
        isBatch ? null :
            <>
                <DatePicker label={t(tBase + 'operationStartDate')} date={date}
                    disabled={store.stockMovement.readOnly}
                    setDate={handleDateChange}
                    status={store.formErrors.movementFieldStatus.getStatus('operationStartAt')} />
                <TimePicker label={t(tBase + 'operationStartTime')} time={time}
                    disabled={store.stockMovement.readOnly}
                    setTime={handleTimeChange}
                    status={store.formErrors.movementFieldStatus.getStatus('operationStartAt')} />
            </>
    )
}

export let OperationEndAt = () => {
    let store = StockMovementContainer.useContainer()
    let isBatch = store.batchMovement != null

    let [date, setDate] = useState(store.stockMovement.operationEndAt);
    let [time, setTime] = useState(store.stockMovement.operationEndAt);

    let handleDateChange = (newDate) => {
        setDate(newDate)
        let operationEndAt = updateDateTime(newDate, time)
        store.setStockMovement({ ...store.stockMovement, operationEndAt: operationEndAt })
    }

    let handleTimeChange = (newTime) => {
        setTime(newTime)
        let operationEndAt = updateDateTime(date, newTime)
        store.setStockMovement({ ...store.stockMovement, operationEndAt: operationEndAt })
    }

    return (
        isBatch ? null :
            <>
                <DatePicker label={t(tBase + 'operationEndDate')} date={date}
                    disabled={store.stockMovement.readOnly}
                    setDate={handleDateChange}
                    status={store.formErrors.movementFieldStatus.getStatus('operationEndAt')} />
                <TimePicker label={t(tBase + 'operationEndTime')} time={time}
                    disabled={store.stockMovement.readOnly}
                    setTime={handleTimeChange}
                    status={store.formErrors.movementFieldStatus.getStatus('operationEndAt')} />
            </>
    )
}

type ProductProps = {
    isIn: boolean
    onChangeOverride?: (mvt: MovementForm) => void
}

export let Product = ({ isIn, onChangeOverride }: ProductProps): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let getStatus = store.formErrors.movementFieldStatus.getStatus
    let onChange = onChangeOverride ?? store.setStockMovement
    let value = isIn
        ? store.stockMovement.destinationProductId ?? ''
        : store.stockMovement.originProductId ?? ''

    return (
        <Select label={t(tBase + 'product')}
            disabled={!store.canEditMovementMetadata() || !!store.associatedDeal}
            value={value}
            choices={store.products.map(x => ({ value: x.id, text: x.code }))}
            onChange={val => { if (val) onChange({ ...store.stockMovement, destinationProductId: val, originProductId: val }) }}
            status={isIn ? getStatus('destinationProductId') : getStatus('originProductId')} />
    )
}

export let OriginProduct = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let getStatus = store.formErrors.movementFieldStatus.getStatus

    return (
        <Select label={t(tBase + 'originProduct')}
            disabled={!store.canEditMovementMetadata() || !!store.associatedDeal}
            value={store.stockMovement.originProductId ?? ''}
            choices={store.products.map(x => ({ value: x.id, text: x.code }))}
            onChange={val => { if (val) store.setStockMovement({ ...store.stockMovement, originProductId: val }) }}
            status={getStatus('originProductId')} />
    )
}

export let DestinationProduct = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let getStatus = store.formErrors.movementFieldStatus.getStatus

    return (
        <Select label={t(tBase + 'destinationProduct')}
            disabled={!store.canEditMovementMetadata() || !!store.associatedDeal}
            value={store.stockMovement.destinationProductId ?? ''}
            choices={store.products.map(x => ({ value: x.id, text: x.code }))}
            onChange={val => { if (val) store.setStockMovement({ ...store.stockMovement, destinationProductId: val }) }}
            status={getStatus('destinationProductId')} />
    )
}

type DutyStatusProps = {
    isIn: boolean
    endAdornment?: JSX.Element
}

export let DutyStatus = ({ isIn, endAdornment }: DutyStatusProps): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let getStatus = store.formErrors.movementFieldStatus.getStatus
    let value = isIn
        ? store.stockMovement.destinationDutyStatus ?? ''
        : store.stockMovement.originDutyStatus ?? ''

    return (
        <Select label={t(tBase + 'dutyStatus')}
            disabled={!store.canEditMovementMetadata() || !!store.associatedDeal}
            hideOnSingleChoice
            value={value}
            choices={store.dutyStatuses}
            onErrorMessage={!store.formErrors.specialRules.isCompanyDutyStatusValid ? t(tBase + 'companyDutyStatusErrorMessage') : undefined}
            onChange={val => { if (val) store.setStockMovement({ ...store.stockMovement, destinationDutyStatus: val, originDutyStatus: val }) }}
            status={isIn ? getStatus('destinationDutyStatus') : getStatus('originDutyStatus')}
            endAdornment={endAdornment}
        />
    )
}

export let OriginDutyStatus = ({ onChangeOverride }: { onChangeOverride?: (mvt: MovementForm) => void }): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let getStatus = store.formErrors.movementFieldStatus.getStatus
    let value = store.stockMovement.originDutyStatus ?? ''
    let onChange = onChangeOverride ?? store.setStockMovement

    return (
        <Select label={t(tBase + 'originDutyStatus')}
            disabled={!store.canEditMovementMetadata() || !!store.associatedDeal}
            hideOnSingleChoice
            value={value}
            choices={store.dutyStatuses}
            onErrorMessage={!store.formErrors.specialRules.isCompanyDutyStatusValid ? t(tBase + 'companyDutyStatusErrorMessage') : undefined}
            onChange={val => { if (val) onChange({ ...store.stockMovement, originDutyStatus: val }) }}
            status={getStatus('originDutyStatus')} />
    )
}

export let DestinationDutyStatus = ({ onChangeOverride }: { onChangeOverride?: (mvt: MovementForm) => void }): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let getStatus = store.formErrors.movementFieldStatus.getStatus
    let value = store.stockMovement.destinationDutyStatus ?? ''
    let onChange = onChangeOverride ?? store.setStockMovement

    return (
        <Select label={t(tBase + 'destinationDutyStatus')}
            disabled={!store.canEditMovementMetadata() || !!store.associatedDeal}
            hideOnSingleChoice
            value={value}
            choices={store.dutyStatuses}
            onErrorMessage={!store.formErrors.specialRules.isCompanyDutyStatusValid ? t(tBase + 'companyDutyStatusErrorMessage') : undefined}
            onChange={val => { if (val) onChange({ ...store.stockMovement, destinationDutyStatus: val }) }}
            status={getStatus('destinationDutyStatus')} />
    )
}
type CompanyProps = {
    onChangeOverride?: (mvt: MovementForm) => void
}

export let Company = ({ onChangeOverride }: CompanyProps): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let onChange = onChangeOverride ?? store.setStockMovement
    let isSalePurchaseMirror = !!store.stockMovement.mirrorMovementId
        && (store.stockMovement.movementType == MovementType.Purchase || store.stockMovement.movementType == MovementType.Sale)

    return (
        <Select label={t(tBase + 'company')}
            disabled={!store.canEditMovementMetadata() || isSalePurchaseMirror || !!store.associatedDeal}
            value={store.stockMovement.company ? store.stockMovement.company : ''}
            choices={store.companys.map(x => ({ value: x.code, text: x.name }))}
            onErrorMessage={!store.formErrors.specialRules.isCompanyDutyStatusValid ? t(tBase + 'companyDutyStatusErrorMessage') : undefined}
            onChange={val => { if (val) onChange({ ...store.stockMovement, company: val }) }}
            status={store.formErrors.movementFieldStatus.getStatus('company')} />
    )
}

export let Counterparty = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let isSalePurchaseMirror = !!store.stockMovement.mirrorMovementId
        && (store.stockMovement.movementType == MovementType.Purchase || store.stockMovement.movementType == MovementType.Sale)

    let filterAvailableCounterpartys = () => {
        let selectedCompanyCode = store.stockMovement.company
        let selectedCompanyName = store.companys.find(x => x.code === selectedCompanyCode)?.name

        return selectedCompanyName
            ? store.counterpartys.filter(x => x.name !== selectedCompanyName).map(x => ({ value: x.id, text: x.name }))
            : store.counterpartys.map(x => ({ value: x.id, text: x.name }))
    }

    return (
        <Select label={t(tBase + 'counterparty')}
            disabled={!store.canEditMovementMetadata() || isSalePurchaseMirror || !store.stockMovement.company || !!store.associatedDeal}
            value={store.stockMovement.counterpartyId ? store.stockMovement.counterpartyId : ''}
            choices={filterAvailableCounterpartys()}
            onChange={val => { if (val) store.setStockMovement({ ...store.stockMovement, counterpartyId: val }) }}
            status={store.formErrors.movementFieldStatus.getStatus('counterpartyId')} />
    )
}

export let Site = ({ isIn }: { isIn: boolean }): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let getStatus = store.formErrors.movementFieldStatus.getStatus
    let value = isIn
        ? store.stockMovement.destinationSite ?? ''
        : store.stockMovement.originSite ?? ''

    return (
        <Select label={t(tBase + 'site')}
            disabled={!store.canEditMovementMetadata() || !!store.associatedDeal}
            value={value}
            choices={store.sites.map(x => ({ value: x.code, text: x.name }))}
            onChange={val => { if (val) store.setStockMovement({ ...store.stockMovement, destinationSite: val, originSite: val }) }}
            status={isIn ? getStatus('destinationSite') : getStatus('originSite')} />
    )
}

type SiteProps = {
    label?: string,
    onChangeOverride?: (mvt: MovementForm) => void
}
export let OriginSite = ({ label, onChangeOverride }: SiteProps): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let getStatus = store.formErrors.movementFieldStatus.getStatus
    let onChange = onChangeOverride ?? store.setStockMovement

    return (
        <Select label={label ?? t(tBase + 'originSite')}
            disabled={!store.canEditMovementMetadata() || !!store.associatedDeal}
            value={store.stockMovement.originSite ?? ''}
            choices={store.sites.map(x => ({ value: x.code, text: x.name }))}
            onChange={val => { if (val) onChange({ ...store.stockMovement, originSite: val }) }}
            status={getStatus('originSite')} />
    )
}

export let DestinationSite = ({ label, onChangeOverride }: SiteProps): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let getStatus = store.formErrors.movementFieldStatus.getStatus
    let onChange = onChangeOverride ?? store.setStockMovement

    return (
        <Select label={label ?? t(tBase + 'destinationSite')}
            disabled={!store.canEditMovementMetadata() || !!store.associatedDeal}
            value={store.stockMovement.destinationSite ?? ''}
            choices={store.sites.map(x => ({ value: x.code, text: x.name }))}
            onChange={val => { if (val) onChange({ ...store.stockMovement, destinationSite: val }) }}
            status={getStatus('destinationSite')} />
    )
}

type MotProps = {
    onChangeOverride?: (mvt: MovementForm) => void
}

export let Mot = ({ onChangeOverride }: MotProps): JSX.Element => {
    let store = StockMovementContainer.useContainer()

    let onChange = onChangeOverride ?? store.setStockMovement

    return (
        <Select label={t(tBase + 'mot')} disabled={store.locked() || store.stockMovement.readOnly}
            value={store.stockMovement.meanOfTransportation ? store.stockMovement.meanOfTransportation : ''}
            choices={store.meanOfTransportations}
            onChange={val => { if (val) onChange({ ...store.stockMovement, meanOfTransportation: val }) }}
            status={store.formErrors.movementFieldStatus.getStatus('meanOfTransportation')} />
    )
}

export let Status = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()

    let movementStatusValues: { value: any, text: string }[] =
        Object.keys(MovementStatus).filter(x => isNaN(+x) && x !== 'Unknown').map((x, i) => { return { value: i, text: x.valueOf() } })

    return (<Select label={t(tBase + 'status')} disabled={store.locked() || store.stockMovement.readOnly}
        value={store.stockMovement.movementStatus || store.stockMovement.movementStatus === 0 ? +store.stockMovement.movementStatus : MovementStatus.Forecast}
        choices={movementStatusValues}
        onChange={val => { if (val) store.setStockMovement({ ...store.stockMovement, movementStatus: +val }) }}
        status={store.formErrors.movementFieldStatus.getStatus('movementStatus')} />
    )
}

const isFromSap = (oldValue, newValue, isFromSap): boolean => {
    return oldValue != newValue ? false : isFromSap
}

export let ReferenceOrder = ({ isMainSapFlow }: MainSapFlowProps): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let sapFlow = store.getSapFlow(isMainSapFlow)

    let label = ''
    switch (sapFlow.movementType) {
        case MovementType.Purchase:
            label = t(tBase + 'purchaseOrder')
            break
        case MovementType.Sale:
            label = t(tBase + 'saleOrder')
            break
        default:
            label = t(tBase + 'stockTransportOrder')
    }

    return (
        <TextField label={label}
            text={sapFlow.referenceOrder}
            onChange={event => store.setSapFlow(isMainSapFlow,
                {
                    ...sapFlow,
                    referenceOrder: event.target.value,
                    isReferenceOrderFromSap: isFromSap(sapFlow.referenceOrder, event.target.value, sapFlow.isReferenceOrderFromSap)
                })}
            status={store.getSapFlowFieldStatus(isMainSapFlow).getStatus('referenceOrder')} />
    )
}

export let MaterialDocument = ({ isMainSapFlow }: MainSapFlowProps): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let sapFlow = store.getSapFlow(isMainSapFlow)

    let materialDocumentDebouncer = useInputDebounce(
        sapFlow.materialDocument,
        val => store.setSapFlow(isMainSapFlow, {
            ...sapFlow,
            materialDocument: val,
            materialDocDate: updateMaterialDocumentDate(val),
            isMaterialDocumentFromSap: isFromSap(sapFlow.materialDocument, val, sapFlow.isMaterialDocumentFromSap)
        }))

    function updateMaterialDocumentDate(materialDocument: string | null): string | null {
        if (materialDocument == null || materialDocument === '')
            return sapFlow.materialDocDate

        let formatMonthOrDay = (number: number): string => ('00' + number).slice(-2)
        let formatYear = (number: number): string => ('0000' + number).slice(-4)
        let today = moment()
        let month = formatMonthOrDay(today.month() + 1)
        let day = formatMonthOrDay(today.date())
        let year = formatYear(today.year())
        return moment.utc(`${month}/${day}/${year}`, 'MM/DD/YYYY').format()
    }

    useEffect(() => materialDocumentDebouncer.setValue(sapFlow.materialDocument),
        [store.stockMovement.mainSapFlow.materialDocument, store.stockMovement.secondSapFlow?.materialDocument])

    let getFieldLabelFromMovementType = (): string => {
        switch (sapFlow.movementType) {
            case MovementType.Purchase: return t(tBase + 'materialDocPurchase')
            case MovementType.Sale: return t(tBase + 'materialDocSale')
            case MovementType.StatusChange: return t(tBase + 'materialDocStatusChange')
            case MovementType.Transfer: return t(tBase + 'materialDocTransfer')
            case MovementType.Rebranding: return t(tBase + 'materialDocRebranding')
            default: return t(tBase + 'materialDoc')
        }
    }

    return (
        <TextField
            label={getFieldLabelFromMovementType()}
            text={materialDocumentDebouncer.value}
            onChange={event => materialDocumentDebouncer.onChange(event.target.value)}
            status={store.getSapFlowFieldStatus(isMainSapFlow).getStatus('materialDocument')} />
    )
}

export let MaterialDocumentDate = ({ isMainSapFlow }: MainSapFlowProps): JSX.Element => {
    let store = StockMovementContainer.useContainer()

    let set = (val) => {
        let newSapFlow = { ...store.getSapFlow(isMainSapFlow), materialDocDate: val }

        if (isMainSapFlow && store.stockMovement.secondSapFlow) {
            let newSecondSapFlow = { ...store.getSapFlow(!isMainSapFlow), materialDocDate: val }
            store.setStockMovement({ ...store.stockMovement, mainSapFlow: newSapFlow, secondSapFlow: newSecondSapFlow })
        } else {
            store.setSapFlow(isMainSapFlow, newSapFlow)
        }
    }

    return (
        <DatePicker
            label={t(tBase + 'materialDocDate')}
            date={store.getSapFlow(isMainSapFlow).materialDocDate}
            setDate={newDate => set(newDate)}
            status={store.getSapFlowFieldStatus(isMainSapFlow).getStatus('materialDocDate')} />
    )
}

export let PaymentTerms = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()

    return (
        <TextField
            label={t(tBase + 'paymentTerms')}
            disabled={true}
            text={store.stockMovement.sapPaymentTerms}
        />
    )
}

export let MeanOfTransportation = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()

    return (
        <TextField
            label={t(tBase + 'meanOfTransportation')}
            disabled={true}
            text={store.stockMovement.sapMeanOfTransportation}
        />
    )
}

export let DeliveryNote = ({ isMainSapFlow }: MainSapFlowProps): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let sapFlow = store.getSapFlow(isMainSapFlow)

    let set = (val) => {
        store.setSapFlow(isMainSapFlow,
            {
                ...sapFlow,
                deliveryNote: val,
                isDeliveryNoteFromSap: isFromSap(sapFlow.deliveryNote, val, sapFlow.isDeliveryNoteFromSap)
            })
    }

    return (
        <TextField label={t(tBase + 'deliveryNote')}
            text={sapFlow.deliveryNote}
            onChange={event => set(event.target.value)}
            status={store.getSapFlowFieldStatus(isMainSapFlow).getStatus('deliveryNote')}
        />
    )
}

export let GoodsIssue = ({ isMainSapFlow }: MainSapFlowProps): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let sapFlow = store.getSapFlow(isMainSapFlow)

    let set = (val) => {
        store.setSapFlow(isMainSapFlow,
            {
                ...sapFlow,
                goodsIssue: val,
                isGoodsIssueFromSap: isFromSap(sapFlow.goodsIssue, val, sapFlow.isGoodsIssueFromSap)
            })
    }

    return (
        <TextField label={t(tBase + 'goodsIssue')}
            text={sapFlow.goodsIssue}
            onChange={event => set(event.target.value)}
            status={store.getSapFlowFieldStatus(isMainSapFlow).getStatus('goodsIssue')} />
    )
}

export let VettingNumber = ({ isMainSapFlow }: MainSapFlowProps): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    return (
        <TextField label={t(tBase + 'vettingNumber')}
            disabled={true}
            text={store.getSapFlow(isMainSapFlow).vettingNumber} />
    )
}

export let ExchangeRate = ({ isMainSapFlow }: MainSapFlowProps): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let exchangeRate = store.getSapFlow(isMainSapFlow).exchangeRate

    if (exchangeRate == null)
        return <></>
    else
        return (
            <TextField label={t(tBase + 'exchangeRate')}
                disabled={true}
                text={store.getSapFlow(isMainSapFlow).exchangeRate} />
        )
}

export let Reference = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()

    return (
        <TextField label={t(tBase + 'referenceExt')} disabled={store.locked() || store.stockMovement.readOnly}
            text={store.stockMovement.reference}
            onChange={event => store.setStockMovement({ ...store.stockMovement, reference: event.target.value })}
            status={store.formErrors.movementFieldStatus.getStatus('reference')} />
    )
}

export let ExternalReference = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()

    return (
        <TextField label={t(tBase + 'externalReference')} disabled={store.locked() || store.stockMovement.readOnly}
            text={store.stockMovement.externalReference}
            onChange={event => store.setStockMovement({ ...store.stockMovement, externalReference: event.target.value })}
            status={store.formErrors.movementFieldStatus.getStatus('externalReference')} />
    )
}

export let Instruction = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()

    return (
        <TextField label={t(tBase + 'instruction')} fullWidth
            disabled={store.stockMovement.readOnly}
            text={store.stockMovement.instruction}
            onChange={event => store.setStockMovement({ ...store.stockMovement, instruction: event.target.value })}
            status={store.formErrors.movementFieldStatus.getStatus('instruction')} />
    )
}

export let PostingDate = ({ isMainSapFlow }: MainSapFlowProps): JSX.Element => {
    let store = StockMovementContainer.useContainer()

    let set = (val) => {

        let newSapFlow = { ...store.getSapFlow(isMainSapFlow), postingDate: val }
        if (isMainSapFlow && store.stockMovement.secondSapFlow) {
            let newSecondSapFlow = { ...store.getSapFlow(!isMainSapFlow), postingDate: val }
            store.setStockMovement({ ...store.stockMovement, mainSapFlow: newSapFlow, secondSapFlow: newSecondSapFlow })
        } else {
            store.setSapFlow(isMainSapFlow, newSapFlow)
        }
    }

    return (
        <DatePicker
            label={t(tBase + 'postingDate')}
            date={store.getSapFlow(isMainSapFlow).postingDate}
            setDate={newDate => set(newDate)}
            status={store.getSapFlowFieldStatus(isMainSapFlow).getStatus('postingDate')} />
    )
}

export let OriginExternalNumber = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()

    return (
        <TextField label={t(tBase + 'originExternalNumber')} disabled={store.stockMovement.readOnly}
            text={store.stockMovement.originExternalNumber}
            onChange={event => store.setStockMovement({ ...store.stockMovement, originExternalNumber: event.target.value })}
            status={store.formErrors.movementFieldStatus.getStatus('originExternalNumber')} />
    )
}

export let DestinationExternalNumber = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()

    return (
        <TextField label={t(tBase + 'destinationExternalNumber')} disabled={store.stockMovement.readOnly}
            text={store.stockMovement.destinationExternalNumber}
            onChange={event => store.setStockMovement({ ...store.stockMovement, destinationExternalNumber: event.target.value })}
            status={store.formErrors.movementFieldStatus.getStatus('destinationExternalNumber')} />
    )
}

export type ModeSwitcherArgs = {
    initiallyDualMode: boolean
    canSwitch: boolean
    forceDualMode?: boolean
    hideArrow?: boolean
    originComponent: JSX.Element
    destinationComponent: JSX.Element
    singleModeComponent: JSX.Element
    onResetDualMode: () => void
    tooltipText: string
    dualModeIconClassName: string
    singleModeIconClassName: string
}

export let ModeSwitcher = (props: ModeSwitcherArgs) => {
    let [isDualMode, setIsDualMode] = useState<boolean>(props.initiallyDualMode || !!props.forceDualMode)
    let switchMode = () => {
        if (!isDualMode) props.onResetDualMode()
        setIsDualMode(!isDualMode)
    }

    return (
        isDualMode
            ? <>
                {props.originComponent}
                {!props.hideArrow &&
                    <DoubleArrow style={{ width: '1em', height: '1em', marginTop: '0.6em', color: defaultColors.grey.dark.color }} />}
                {props.destinationComponent}
                {props.canSwitch && !props.forceDualMode &&
                    <Tooltip title={<Typography variant='body1'>{props.tooltipText}</Typography>} placement='top'>
                        <span className={props.dualModeIconClassName}>
                            <IconButton onClick={switchMode} >
                                <FlipOutlined />
                            </IconButton>
                        </span>
                    </Tooltip>}
            </>
            : <>
                {props.singleModeComponent}
                {props.canSwitch &&
                    <Tooltip title={<Typography variant='body1'>{props.tooltipText}</Typography>} placement='top'>
                        <span className={props.singleModeIconClassName}>
                            <IconButton onClick={switchMode} >
                                <FlipOutlined />
                            </IconButton>
                        </span>
                    </Tooltip>}
            </>
    )
}

export let CreateDealButton = ({ buttonClass }: { buttonClass: string }): JSX.Element => {
    let store = StockMovementContainer.useContainer()

    let onClickCreateDeal = () => {
        store.formErrors.highlightErrors()
        if (!store.formErrors.hasMovementError()) {
            if (hasFeature('SelectPricingType'))
                store.setSelectPricingTypeFor('deal')
            else
                store.setIsConfirmDealOpen(true)
        }
        else
            store.setStockMovement({ ...store.stockMovement })
    }

    if (store.batchMovement == null)
        return (
            <Button
                label={t(tBase + 'createDealButton')}
                onClick={onClickCreateDeal}
                className={buttonClass} />)
    else return <div />
}

export let MirrorMovementButton = ({ buttonClass }: { buttonClass: string }): JSX.Element => {
    let stockEdit = StockMovementContainer.useContainer()
    return stockEdit.stockMovement.showMirrorMovement ?
        <Button
            label={t(tBase + 'mirrorMovementButton')}
            onClick={() => popupNavigator.open('movement', stockEdit.stockMovement.mirrorMovementId)}
            className={buttonClass} />
        : <div />
}

export let AssociatedVessel = ({ text }: { text: string }): JSX.Element => {
    return (<TextField label={t(tBase + 'vessel')} text={text} disabled />)
}

export let Transporter = (): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let transporterName = store.counterpartys.find(x => x.id == store.stockMovement.transporterId)?.name
    return (
        <TextField label={t(tBase + 'transporter')}
            disabled={true}
            text={transporterName} />)
}

export let AssignDealButton = ({ buttonClass }: { buttonClass: string }): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let onClickAssignDeal = async () => {
        await store.trySave()
        await store.openAssignableDeals()
    }

    if (store.batchMovement == null)
        return (
            <Button
                label={t(tBase + 'assignDealButton')}
                onClick={onClickAssignDeal}
                className={buttonClass} />)
    else
        return <div />
}

export let AssignVesselButton = ({ buttonClass }: { buttonClass: string }): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let linkStore = LinkVesselMovementContainer.useContainer()

    let onClickAssignVessel = async () => {
        store.formErrors.highlightErrors()
        if (!store.formErrors.hasMovementError()) {
            await store.trySave()

            if (hasFeature('SelectPricingType') && !store.stockMovement.associatedDealId)
                store.setSelectPricingTypeFor('vessel')
            else
                linkStore.openAssignableVessels()
        }
    }

    if (store.batchMovement == null)
        return (
            <Button
                label={t(tBase + 'assignVesselButton')}
                onClick={onClickAssignVessel}
                className={buttonClass} />)
    else
        return <div />
}

export let _Comment = ({ classes }): JSX.Element => {
    let store = StockMovementContainer.useContainer()
    let isReadOnly = !store.associatedTruckId && store.stockMovement.readOnly
    let associationKey = `movement-${store.stockMovement.id}`

    return (
        <div className={classes.commentRow}>
            <CommentContent associationKey={associationKey} />
            <CommentButton withPadding
                associationKey={associationKey}
                readOnly={isReadOnly} />
        </div>
    )
}

let commentRowStyles = _ =>
    createStyles({
        commentRow: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%'
        }
    })

export let Comment = withStyles(commentRowStyles, muiOptions)(_Comment)