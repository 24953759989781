import moment from 'moment'
import React from 'react'
import { createStyles, withStyles, Typography, Tooltip, Chip } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { t } from '../../../infrastructure/i18nextHelper'
import { ColumnDescriptor, DataTable, SapChip } from '../../common/customComponents'
import { defaultColors, muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { DealMovementItem } from '../dealModels'
import { DealContainer, dealDialog } from './dealEditStore'
import { popupNavigator } from '../../../infrastructure/popupNavigator'
import { MovementStatus, MovementType, SapFlowStepStatus } from "../../stock/stockModels"
import { MovementStatusIcon } from '../../stock/MovementStatusIcon'
import { MovementTypeIcon, MovementMotIcon } from '../../stock/_movementIcon'
import { ActionDescriptor } from '../../common/components/table/table'


function DealMovements({ classes }) {
    let store = DealContainer.useContainer()

    let getTotalFilteredMovementsVolume = () => {
        let currentMovements = store.deal?.movements
        if (currentMovements == null) return
        return currentMovements.reduce((acc, cur) => acc + (cur.volume ?? 0), 0)
    }

    let openMovementVessel = (x: DealMovementItem) => {
        if (x.associatedVesselId)
            popupNavigator.open('vessel', x.associatedVesselId)
        setTimeout(() => dealDialog.close(), 1000)
    }

    let openMovementTruck = (x: DealMovementItem) => {
        if (x.associatedTruckId)
            popupNavigator.open('truck', x.associatedTruckId)
        setTimeout(() => dealDialog.close(), 1000)
    }

    let getMovementTableColumns = () => {
        let columns: ColumnDescriptor<DealMovementItem>[] = [
            { name: t('stock.label.movement.date'), value: x => moment(x.date?.toString()).format('MM/DD/YYYY') },
            { name: t('stock.label.movement.type'), htmlFor: x => <MovementTypeIcon movementType={x.movementType ?? MovementType.Unknown} /> },
            {
                name: t('stock.label.movement.referenceExt'),
                value: x => x.reference,
                wrapText: true,
            },
            {
                name: t('stock.label.originDestination'), htmlFor: x =>
                    <div className={classes.textSubTextContainer}>
                        <div>{x.text}</div>
                        <div className={classes.subText}>{x.subText}</div>
                    </div>
            },
            { name: t('stock.label.movement.mot'), htmlFor: x => <MovementMotIcon meanOfTransportation={x.meanOfTransportation ?? ''} /> },
            {
                name: t('stock.label.movement.volume', { unit: store.unit }),
                textAlign: 'right',
                total: getTotalFilteredMovementsVolume(),
                value: x => x.volume ? Math.round(x.volume).toString() : null,
                totalLabel: t('components.table.total')
            },
            {
                name: t('stock.label.movement.status'), value: x => MovementStatus[x.movementStatus ?? -1],
                htmlFor: x => <MovementStatusIcon movementItem={x} classes={classes} />
            }
        ]

        let isMultiSapFlow = (x: DealMovementItem) => x.secondSapFlowListItem != null && x.secondSapFlowListItem.steps.length > 0

        let steps = (x: DealMovementItem): SapFlowStepStatus[] => {
            if (isMultiSapFlow(x))
                return [x.mainSapFlowListItem.steps[0], x.secondSapFlowListItem!.steps[0]]
            else
                return x.mainSapFlowListItem.steps
        }

        columns.push(
            {
                name: t('stock.label.movement.sap'), value: x => MovementStatus[x.movementStatus ?? -1],
                htmlFor: x => {
                    return (<>
                        {steps(x).map((stepStatus, index) => {
                            return <SapChip
                                className='default'
                                key={index}
                                hasValue={stepStatus.isCompleted}
                                isFromSap={stepStatus.isFromSap}
                                label={isMultiSapFlow(x) && stepStatus.isCompleted ? stepStatus.value : stepStatus.name} />
                        }
                        )}
                    </>)
                }
            })
        return columns
    }

    let confirmUnassign = (x: DealMovementItem) => {
        store.setDealMovement(x)
        store.setUnAssignConfirmDialogOpen(true)
    }

    let actions: ActionDescriptor<DealMovementItem>[] = [
        {
            name: 'UnAssign', action: (x: DealMovementItem) => confirmUnassign(x),
            icon:
                <Tooltip title={<Typography variant='body1'>{t('deals.label.unAssignDealMovement')}</Typography>} placement='top'>
                    <Icons.LinkOffOutlined />
                </Tooltip>,
            isBodyAction: true,
            disabled: _ => false
        },
        {
            name: 'Vessel', action: (x: DealMovementItem) => openMovementVessel(x),
            icon:
                <Tooltip title={<Typography variant='body1'>{t('deals.label.goToVessel')}</Typography>} placement='top'>
                    <Icons.DirectionsBoatOutlined />
                </Tooltip>,
            isBodyAction: true,
            disabled: (x: DealMovementItem) => !x.associatedVesselId
        },
        {
            name: 'truck', action: (x: DealMovementItem) => openMovementTruck(x),
            icon:
                <Tooltip title={<Typography variant='body1'>{t('deals.label.goToTruck')}</Typography>} placement='top'>
                    <Icons.LocalShippingOutlined />
                </Tooltip>,
            isBodyAction: true,
            disabled: (x: DealMovementItem) => !x.associatedTruckId
        }
    ]

    let onClickMovement = (dealMovement: DealMovementItem) => {
        popupNavigator.open('movement', dealMovement.movementId)
    }

    return (
        <div>
            {!!store.deal && !!store.deal.movements?.length
                ? <DataTable
                    items={store.deal.movements}
                    isSelectable={false}
                    tableId={'deal-movements-table'}
                    idSelector={(x: DealMovementItem) => x.movementId}
                    onClick={onClickMovement}
                    columns={getMovementTableColumns()}
                    actions={actions} />
                : null
            }
        </div>
    )
}

let styles = theme => createStyles({
    chipRoot: {
        marginLeft: '0.2em',
        marginRight: '0.2em',
        borderRadius: '7px',
    }
})

export default withStyles(styles, muiOptions)(DealMovements)